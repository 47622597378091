<template>
  <MobileDefault v-if="isMobile">
    <div class="container-bg-white container-space-mobile-default">
      <h1 class="text-sm">Nâng cấp tài khoản</h1>
      <a-spin style="height: 80vh; width: 100%; display: grid; place-items: center" v-if="!voucherItem || isPlanCreating"></a-spin>
      <div v-else class="mb-5">
        <div class="flex justify-space-between">
          <div class="flex flex-col justify-space-between">
            <h2 class="text-sm font-normal"><span class="border-left-line-mobile"></span>Thông tin gói cước</h2>
            <div class="action-container leading-8">
              <p><span v-if="$route.name === 'payment'" class="text-note text-action" @click="onChangeVoucher">Đổi gói</span></p>
              <p><span class="text-note" @click="onClickShowDiscountVoucher">
                <span v-if="isAppliedDiscountVoucher" class="text-green-500 text-action">Đã áp dụng Vouhcer</span>
                <span v-else class="text-action">Voucher giảm giá</span>
              </span></p>
            </div>
          </div>
          <div class="flex flex-col justify-space-between items-center text-center">
            <div v-if="voucherItem.name" class="plan-pricing">
              {{ voucherItem.name }}
            </div>
            <div class="my-2">
              <div v-if="voucherItem.content || voucherItem.discountPrice" class="text-xl font-semibold">{{ voucherItem.discountPrice || voucherItem.price | formatCurrency}}</div>
              <div v-if="voucherItem.discountPrice" class="plan-sub-content" style="text-decoration: line-through; color: gray;">{{ voucherItem.price | formatCurrency}}</div>
            </div>
            <a-select @change="onChangePricingPlanDuration" :default-value="voucherItem.duration" option-label-prop="label" style="width: 190px">
              <template v-for="planDuration in localPricingPlanDuration">
                <a-select-option :value="planDuration.id" :key="planDuration.id"  :label="planDuration.label" class="text-align-center">
                  <a-icon v-if="isPlanCreating" type="loading"/>
                  <div>{{planDuration.label}} <span v-if="planDuration.promotedText"> - <span class="text-green-500">{{planDuration.promotedText}}</span></span></div>
                </a-select-option>
              </template>
            </a-select>
          </div>
        </div>
      </div>
      <h2 v-if="voucherItem && !isPlanCreating" class="text-sm font-normal my-2"><span class="border-left-line-mobile"></span>Thanh toán</h2>
      <a-tabs v-if="voucherItem && !isPlanCreating" default-active-key="qrcode_transfer" style="width: calc(100% + 28px); margin-left: -14px">
        <a-tab-pane key="personal_manual_transfer">
          <span slot="tab" class="text-xs">
            Chuyển Khoản
          </span>
          <div class="bank-section text-xs">
            <template v-for="bankAccount in LIST_BANK_ACCOUNT">
              <div v-if="bankAccount.type === 'personal'" :key="bankAccount.id" class="bank-account-container">
                <div class="status-box">
                  <span style="text-transform: uppercase; margin-left: 14px;">Thông tin thanh toán</span>
                </div>
                <div class="bank-info-mobile">
                  <strong class="margin-right-25">{{ bankAccount.bankName }}</strong>
                  <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="16px" width=""
                       :alt="`${bankAccount.bankName}_logo`">
                </div>
                <div class="bank-info-mobile">
                  <span>Số tài khoản: </span>
                  <div>
                    <strong>{{ bankAccount.accountNumber }}</strong>
                    <div class="tooltip">
                      <div type="default" size="small"
                           @click="onClickCopy(bankAccount.accountNumber, 'account_number_tooltip_mobile')">
                        <span class="tooltiptext" id="account_number_tooltip_mobile">Đã copy</span>
                        <a-icon type="copy" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bank-info-mobile">
                  <span class="flex-shrink-0">Chủ tài khoản: </span>
                  <div class="flex">
                    <strong class="text-right" style="text-transform: uppercase">{{ bankAccount.accountName }}</strong>
                    <div class="tooltip">
                      <div type="default" size="small"
                           @click="onClickCopy(bankAccount.accountName, 'account_owner_tooltip_mobile')">
                        <span class="tooltiptext" id="account_owner_tooltip_mobile">Đã copy</span>
                        <a-icon type="copy" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bank-info-mobile">
                  <span>Số tiền: </span>
                  <div v-if="voucherItem.discountPrice">
                    <strong>{{ voucherItem.discountPrice | formatCurrency }}</strong>
                    <div class="tooltip">
                      <div type="default" size="small"
                           @click="onClickCopy(voucherItem.discountPrice, 'discount_price_tooltip_mobile')">
                        <span class="tooltiptext" id="discount_price_tooltip_mobile">Đã copy</span>
                        <a-icon type="copy" />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <strong>{{ voucherItem.price | formatCurrency }}</strong>
                    <div class="tooltip">
                      <div type="default" size="small" @click="onClickCopy(voucherItem.price, 'price_tooltip_mobile')">
                        <span class="tooltiptext" id="price_tooltip_mobile">Đã copy</span>
                        <a-icon type="copy" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bank-info-mobile" style="border-bottom: none">
                  <span>Nội dung chuyển khoản <strong class="text-primary-500">*</strong>: </span>
                  <div>
                    <span class="payment-status status-process">{{ voucherCode }}</span>
                    <div class="tooltip">
                      <div type="default" size="small" @click="onClickCopy(voucherCode, 'transfer_content_tooltip_mobile')">
                        <span class="tooltiptext" id="transfer_content_tooltip_mobile">Đã copy</span>
                        <a-icon type="copy" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cta-mobile-section">
                  <template v-if="timeCountDown > 0 && !isSuccessfulPayment && percentPaymentProgress === 0">
                    <div class="cta-btn" @click="onClickVerify()">
                      <a-icon type="check-circle"/>
                      Xác nhận đã chuyển khoản
                      <!--                  <a-spin style="color: white; margin-left: 4px" v-if="percentPaymentProgress > 0"></a-spin>-->
                    </div>
                  </template>
                  <template v-else-if="isSuccessfulPayment">
                    <div class="cta-btn" @click="onClickSearch">
                      Bắt đầu sử dụng
                    </div>
                  </template>
                  <template v-else-if="isSuccessfulPayment === false">
                    <div class="cta-btn" @click="onClickReBuy">
                      Làm lại
                    </div>
                  </template>
                </div>
                <div class="mobile-view text-center text-xs">
                  <p class="status-box" v-if="percentPaymentProgress >= 0">
                    <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="payment-status">Chờ thanh toán</span>
                    <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100"
                          class="">Đang xác minh thanh toán <span class="payment-status status-process"
                                                                  v-if="timeCountDown"> - <strong>{{ timeCountDown | formatTime }}</strong></span></span>
                    <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon
                      type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>
                    <span v-else-if="isSuccessfulPayment === false">
                      <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span>
                      <br>
                      <span class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span class="text-action" @click="onClickSupport">hỗ trợ</span></span></span>
                  </p>
                </div>
                <div class="note-section" style="margin-left: 14px; margin-top: 12px">
                  <p style="font-size: 12px"><span class="text-primary-500">*</span>Bắt buộc</p>
                  <p style="font-size: 10px;">Nội dung chuyển khoản bắt buộc là <span class="payment-status status-process">{{ voucherCode }}</span> để việc thanh toán được xác minh tự động (2-5 phút)
                  </p>
                </div>
              </div>
            </template>
          </div>
        </a-tab-pane>
<!--        <a-tab-pane key="company_manual_transfer">-->
<!--          <span slot="tab" class="text-xs">-->
<!--            Thanh toán có lấy VAT-->
<!--          </span>-->
<!--          <div class="bank-section text-xs">-->
<!--            <template v-for="bankAccount in LIST_BANK_ACCOUNT">-->
<!--              <div v-if="bankAccount.type === 'company'" :key="bankAccount.id" class="bank-account-container">-->
<!--                <div class="status-box">-->
<!--                  <span style="text-transform: uppercase; margin-left: 14px;">Thông tin thanh toán</span>-->
<!--                </div>-->
<!--                <div class="bank-info-mobile">-->
<!--                  <strong class="margin-right-25">{{ bankAccount.bankName }}</strong>-->
<!--                  <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="16px" width=""-->
<!--                       :alt="`${bankAccount.bankName}_logo`">-->
<!--                </div>-->
<!--                <div class="bank-info-mobile">-->
<!--                  <span>Số tài khoản: </span>-->
<!--                  <div>-->
<!--                    <strong>{{ bankAccount.accountNumber }}</strong>-->
<!--                    <div class="tooltip">-->
<!--                      <div type="default" size="small"-->
<!--                           @click="onClickCopy(bankAccount.accountNumber, 'company_account_number_tooltip_mobile')">-->
<!--                        <span class="tooltiptext" id="company_account_number_tooltip_mobile">Đã copy</span>-->
<!--                        <a-icon type="copy" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="bank-info-mobile">-->
<!--                  <span class="flex-shrink-0">Chủ tài khoản: </span>-->
<!--                  <div class="flex">-->
<!--                    <strong class="text-right" style="text-transform: uppercase">{{ bankAccount.accountName }}</strong>-->
<!--                    <div class="tooltip">-->
<!--                      <div type="default" size="small"-->
<!--                           @click="onClickCopy(bankAccount.accountName, 'company_account_owner_tooltip_mobile')">-->
<!--                        <span class="tooltiptext" id="company_account_owner_tooltip_mobile">Đã copy</span>-->
<!--                        <a-icon type="copy" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="bank-info-mobile">-->
<!--                  <span>Số tiền: </span>-->
<!--                  <div v-if="voucherItem.discountPrice">-->
<!--                    <strong>{{ voucherItem.discountPrice | formatCurrency }}</strong>-->
<!--                    <div class="tooltip">-->
<!--                      <div type="default" size="small"-->
<!--                           @click="onClickCopy(voucherItem.discountPrice, 'company_discount_price_tooltip_mobile')">-->
<!--                        <span class="tooltiptext" id="company_discount_price_tooltip_mobile">Đã copy</span>-->
<!--                        <a-icon type="copy" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-else>-->
<!--                    <strong>{{ voucherItem.price | formatCurrency }}</strong>-->
<!--                    <div class="tooltip">-->
<!--                      <div type="default" size="small" @click="onClickCopy(voucherItem.price, 'company_price_tooltip_mobile')">-->
<!--                        <span class="tooltiptext" id="company_price_tooltip_mobile">Đã copy</span>-->
<!--                        <a-icon type="copy" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="bank-info-mobile" style="border-bottom: none">-->
<!--                  <span>Nội dung chuyển khoản <strong class="text-primary-500">*</strong>: </span>-->
<!--                  <div>-->
<!--                    <span class="payment-status status-process">{{ voucherCode }}</span>-->
<!--                    <div class="tooltip">-->
<!--                      <div type="default" size="small" @click="onClickCopy(voucherCode, 'company_transfer_content_tooltip_mobile')">-->
<!--                        <span class="tooltiptext" id="company_transfer_content_tooltip_mobile">Đã copy</span>-->
<!--                        <a-icon type="copy" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="cta-mobile-section">-->
<!--                  <template v-if="timeCountDown > 0 && !isSuccessfulPayment && percentPaymentProgress === 0">-->
<!--                    <div class="cta-btn" @click="onClickVerify()">-->
<!--                      <a-icon type="check-circle"/>-->
<!--                      Xác nhận đã chuyển khoản-->
<!--                      &lt;!&ndash;                  <a-spin style="color: white; margin-left: 4px" v-if="percentPaymentProgress > 0"></a-spin>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-else-if="isSuccessfulPayment">-->
<!--                    <div class="cta-btn" @click="onClickSearch">-->
<!--                      Bắt đầu sử dụng-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-else-if="isSuccessfulPayment === false">-->
<!--                    <div class="cta-btn" @click="onClickReBuy">-->
<!--                      Làm lại-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </div>-->
<!--                <div class="mobile-view text-center text-xs">-->
<!--                  <p class="status-box" v-if="percentPaymentProgress >= 0">-->
<!--                    <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="payment-status">Chờ thanh toán</span>-->
<!--                    <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100"-->
<!--                          class="">Đang xác minh thanh toán <span class="payment-status status-process"-->
<!--                                                                  v-if="timeCountDown"> - <strong>{{ timeCountDown | formatTime }}</strong></span></span>-->
<!--                    <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon-->
<!--                      type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>-->
<!--                    <span v-else-if="isSuccessfulPayment === false">-->
<!--                      <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span>-->
<!--                      <br>-->
<!--                      <span class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span class="text-action" @click="onClickSupport">hỗ trợ</span></span></span>-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="note-section" style="margin-left: 14px; margin-top: 12px">-->
<!--                  <p style="font-size: 12px"><span class="text-primary-500">*</span>Bắt buộc</p>-->
<!--                  <p style="font-size: 10px;">Nội dung chuyển khoản bắt buộc là <span class="payment-status status-process">{{ voucherCode }}</span> để việc thanh toán được xác minh tự động (2-5 phút)-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->
<!--        </a-tab-pane>-->
        <a-tab-pane key="qrcode_transfer">
          <span slot="tab" class="text-xs">
            VNPayQR Code
          </span>
          <div class="bank-section flex flex-col items-center justify-center mx-8 text-xs">
            <p class="text-sm font-medium text-center text-gray-700">Quét mã VNPayQR với tính năng chuyển khoản</p>
            <div class="relative mt-4 mb-4" style="width: min-content; --border-width: 20px; overflow: hidden">
              <img class="" :src="urlQRCode" alt="qrcode" height="120px" width="120px" style="transform: scale(1.05)">
              <div class="absolute top-0 left-0 border-t border-l border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
              <div class="absolute top-0 right-0 border-t border-r border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
              <div class="absolute bottom-0 left-0 border-b border-l border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
              <div class="absolute bottom-0 right-0 border-b border-r border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
            </div>
            <p class="text-center" style="font-size: 10px"><a-icon type="scan" style="transform: scale(1.5)" class="mr-2"/> <span> Sử dụng app ngân hàng của bạn để chuyển khoản qua quét mã VNPayQR</span></p>
            <p class="text-center my-4" style="width: calc(100% + 40px); font-size: 10px">Bấm <strong>"Xác nhận đã chuyển khoản"</strong> sau khi chuyển tiền thành công</p>
            <div class="cta-mobile-section">
              <template v-if="typeof isSuccessfulPayment !== 'boolean' && timeCountDown > 0 && percentPaymentProgress === 0">
                <div class="cta-btn" @click="onClickVerify(1/2)">
                  <a-icon type="check-circle"/>
                  Xác nhận đã chuyển khoản
                </div>
              </template>
              <template v-else-if="isSuccessfulPayment === true">
                <div class="cta-btn" @click="onClickSearch">
                  Bắt đầu sử dụng
                </div>
              </template>
              <template v-else-if="isSuccessfulPayment === false">
                <div class="cta-btn" @click="onClickReBuy">
                  Làm lại
                </div>
              </template>
            </div>
            <div class="mobile-view">
              <p class="status-box text-center text-gray-400" v-if="percentPaymentProgress >= 0">
                <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="">Chờ thanh toán</span>
                <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100" class="text-gray-700">
                    Đang xác minh thanh toán
                    <span v-if="timeCountDown" class="payment-status status-process"> - <strong>{{ timeCountDown | formatTime }}</strong></span>
                  </span>
                <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success">
                    <a-icon type="check-circle" style="margin-right: 4px"/>Thanh toán thành công !
                  </span>
                <span v-else-if="isSuccessfulPayment === false" style="line-height: 20px">
                    <span class="payment-status status-failure">
                      <a-icon type="info-circle" style="margin-right: 4px"/>Xác minh thanh toán Thất bại
                    </span>
                    <br>
                    <i class="text-gray-400">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span
                      class="text-action" @click="onClickSupport">hỗ trợ</span></i>
                    </span>
              </p>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal v-model="isDisplayDiscountVoucherInput"
             :closable="true"
             :footer="null"
             :maskClosable="true"
             @cancel="onCloseVoucherPopup"
    >
      <div slot="title" class="text-base">
        Voucher giảm giá
      </div>
      <div class="discount-voucher-container" v-if="isDisplayDiscountVoucherInput || isAppliedDiscountVoucher">
        <a-input v-if="!isAppliedDiscountVoucher" placeholder="Voucher giảm giá"
                        size="default"
                        @search="onApplyDiscountVoucher"
                        v-model="discountVoucherCode"
        >
        </a-input>
        <div v-else class="voucher-container">
          <div class="voucher-box">
            {{ discountVoucherCode }}
            <svg @click="onRemoveDiscountVoucher" class="remove-action" viewBox="64 64 896 896" data-icon="close"
                 width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
              <path
                d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </div>
        </div>
        <p v-if="!discountVoucherCode" class="mt-2 text-gray-400">Vui lòng nhập mã giảm giá vào ô trống</p>
        <div v-if="isInvalidDiscountVoucher" class="text-error">Voucher không hợp lệ</div>
        <div v-else-if="isAppliedDiscountVoucher" class="text-success">Áp dụng thành công Voucher
          <div v-if="voucherItem.discountPercent" class="text-success">(Giảm {{ voucherItem.discountPercent * 100 }}%)
          </div>
          <div v-else-if="voucherItem.discountDirectValue" class="text-success">(Giảm {{voucherItem.discountDirectValue}}đ)</div>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <a-button style="width: 100px" class="mr-2" @click="onClickCancelVoucher">Hủy</a-button>
        <a-button type="primary" style="width: 100px" @click="onApplyDiscountVoucher">Áp dụng</a-button>
      </div>
    </a-modal>
  </MobileDefault>
  <Default v-else class="">
    <div class="payment-page container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Nâng cấp tài khoản</h1>
      <a-spin style="height: 80vh; width: 100%; display: grid; place-items: center" v-if="!voucherItem || isPlanCreating"></a-spin>
      <div class="page-content" v-else>
        <div class="payment-section">
          <div class="voucher-info-section">
            <p class="text-2xl">Thông tin gói cước</p>
            <div class="plan">
              <div class="plan-content-container">
                <div v-if="voucherItem.content || voucherItem.discountPrice" class="plan-content">{{ voucherItem.discountPrice || voucherItem.price | formatCurrency}}</div>
                <div v-if="voucherItem.discountPrice" class="plan-sub-content old-price">{{ voucherItem.price | formatCurrency}}</div>
                <div v-else-if="voucherItem.promotedText" class="plan-sub-content text-green-500">{{ voucherItem.promotedText }}</div>
              </div>
              <div v-if="voucherItem.name" class="plan-pricing">
                {{ voucherItem.name }}
              </div>
              <div>
                <a-select @change="onChangePricingPlanDuration" :default-value="voucherItem.duration" option-label-prop="label" style="width: 200px">
                  <template v-for="planDuration in localPricingPlanDuration">
                    <a-select-option :value="planDuration.id" :key="planDuration.id"  :label="planDuration.label" class="text-align-center">
                      <a-icon v-if="isPlanCreating" type="loading"/>
                      <div>{{planDuration.label}} <span v-if="planDuration.promotedText"> - <span class="text-green-500">{{planDuration.promotedText}}</span></span></div>
                    </a-select-option>
                  </template>
                </a-select>
              </div>
            </div>
            <div class="action-container">
              <span v-if="$route.name === 'payment'" class="text-note text-action"
                    @click="onChangeVoucher">Đổi gói</span>
              <span class="text-note text-action" @click="onClickShowDiscountVoucher">Voucher giảm giá</span>
            </div>
            <div class="discount-voucher-container" v-if="isDisplayDiscountVoucherInput || isAppliedDiscountVoucher">
              <a-input-search v-if="!isAppliedDiscountVoucher" placeholder="Voucher giảm giá"
                              size="default"
                              @search="onApplyDiscountVoucher"
                              v-model="discountVoucherCode"
              >
                <a-button slot="enterButton">
                  Áp dụng
                </a-button>
              </a-input-search>
              <div v-else class="voucher-container">
                <div class="voucher-box">
                  {{ discountVoucherCode }}
                  <svg @click="onRemoveDiscountVoucher" class="remove-action" viewBox="64 64 896 896" data-icon="close"
                       width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
                    <path
                      d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </div>
              </div>
              <div v-if="isInvalidDiscountVoucher" class="text-error">Voucher không hợp lệ</div>
              <div v-else-if="isAppliedDiscountVoucher" class="text-success">Áp dụng thành công Voucher
                <div v-if="voucherItem.discountPercent" class="text-success">(Giảm {{ voucherItem.discountPercent * 100 }}%)
                </div>
                <div v-else-if="voucherItem.discountDirectValue" class="text-success">(Giảm {{voucherItem.discountDirectValue}}đ)</div>
              </div>
              <div></div>
            </div>
          </div>
          <a-tabs class="bank-section" default-active-key="qrcode_transfer">
            <a-tab-pane key="personal_manual_transfer">
              <span slot="tab">
                <a-icon type="dollar"/>
                Chuyển Khoản
              </span>
              <div class="bank-section">
                <template v-for="bankAccount in LIST_BANK_ACCOUNT">
                  <div v-if="bankAccount.type === 'personal'" :key="bankAccount.id" class="bank-account-container">
                    <div class="status-box">
                      <strong style="text-transform: uppercase; margin-right: 8px">Thông tin thanh toán</strong>
                    </div>
                    <div class="bank-info">
                      <strong>{{ bankAccount.bankName }}</strong>
                      <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="20px" width=""
                           :alt="`${bankAccount.bankName}_logo`">
                    </div>
                    <div v-if="bankAccount.accountAddress">{{ bankAccount.accountAddress }}</div>
                    <div class="bank-info">
                      <span>Số tài khoản: </span>
                      <div>
                        <strong>{{ bankAccount.accountNumber }}</strong>
                        <div class="tooltip">
                          <a-button type="default" size="small"
                                    @click="onClickCopy(bankAccount.accountNumber, 'account_number_tooltip')">
                            <span class="tooltiptext" id="account_number_tooltip">Đã copy</span>
                            <span>copy</span>
                          </a-button>
                        </div>
                      </div>
                    </div>
                    <div class="bank-info">
                      <span>Chủ tài khoản: </span>
                      <div>
                        <strong style="text-transform: uppercase">{{ bankAccount.accountName }}</strong>
                        <div class="tooltip">
                          <a-button type="default" size="small"
                                    @click="onClickCopy(bankAccount.accountName, 'account_owner_tooltip')">
                            <span class="tooltiptext" id="account_owner_tooltip">Đã copy</span>
                            <span>copy</span>
                          </a-button>
                        </div>
                      </div>
                    </div>
                    <div class="bank-info">
                      <span>Số tiền: </span>
                      <div v-if="voucherItem.discountPrice">
                        <strong>{{ voucherItem.discountPrice | formatCurrency }}</strong>
                        <div class="tooltip">
                          <a-button type="default" size="small"
                                    @click="onClickCopy(voucherItem.discountPrice, 'discount_price_tooltip')">
                            <span class="tooltiptext" id="discount_price_tooltip">Đã copy</span>
                            <span>copy</span>
                          </a-button>
                        </div>
                      </div>
                      <div v-else>
                        <strong>{{ voucherItem.price | formatCurrency }}</strong>
                        <div class="tooltip">
                          <a-button type="default" size="small" @click="onClickCopy(voucherItem.price, 'price_tooltip')">
                            <span class="tooltiptext" id="price_tooltip">Đã copy</span>
                            <span>copy</span>
                          </a-button>
                        </div>
                      </div>
                    </div>
                    <div class="bank-info">
                      <span>Nội dung chuyển khoản <strong class="text-required">(bắt buộc)</strong>: </span>
                      <div>
                        <span class="payment-status status-process">{{ voucherCode }}</span>
                        <div class="tooltip">
                          <a-button type="default" size="small" @click="onClickCopy(voucherCode, 'transfer_content_tooltip')">
                            <span class="tooltiptext" id="transfer_content_tooltip">Đã copy</span>
                            <span>copy</span>
                          </a-button>
                        </div>
                      </div>
                    </div>
                    <div class="cta-section flex-row justify-space-between">
                      <div>
                        <p class="status-box" v-if="percentPaymentProgress >= 0">
                          <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="payment-status">Chờ thanh toán</span>
                          <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100"
                                class="payment-status status-process">Đang xác minh thanh toán <span
                            v-if="timeCountDown"> - <strong>{{ timeCountDown | formatTime }}</strong></span></span>
                          <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon
                            type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>
                          <span v-else-if="isSuccessfulPayment === false">
                          <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span><span
                            class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span
                            class="text-action" @click="onClickSupport">hỗ trợ</span></span>
                        </span>
                        </p>
                        <!--                  <p v-if="$route.name === 'payment'">Thời gian <span v-if="timeCountDown > 0">còn lại:</span><span v-else>đã </span> <strong>{{timeCountDown | formatTime}}</strong></p>-->
                      </div>
                      <template v-if="timeCountDown > 0 && !isSuccessfulPayment && percentPaymentProgress === 0">
                        <a-button type="primary" size="large" @click="onClickVerify()">
                          <a-icon type="check-circle"/>
                          Xác nhận đã chuyển khoản
                          <!--                  <a-spin style="color: white; margin-left: 4px" v-if="percentPaymentProgress > 0"></a-spin>-->
                        </a-button>
                      </template>
                      <template v-else-if="isSuccessfulPayment">
                        <a-button type="primary" size="large" @click="onClickSearch">
                          Bắt đầu sử dụng
                        </a-button>
                      </template>
                      <template v-else-if="isSuccessfulPayment === false">
                        <a-button type="primary" size="large" @click="onClickReBuy">
                          Làm lại
                        </a-button>
                      </template>
                    </div>
                    <!--              <a-progress v-if="percentPaymentProgress > 0" :percent="percentPaymentProgress" :status="progressStatus" :show-info="progressInfo"/>-->
                    <div class="note-section">
                      <strong class="text-required">Quan trọng:</strong>
                      <ul class="section-content">
                        <li>Nội dung chuyển khoản bắt buộc là <span
                          class="payment-status status-process">{{ voucherCode }}</span> để việc thanh toán được xác minh tự
                          động (2-5 phút)
                        </li>
                        <!--                  <li v-if="urlStaticPaymentView">Theo dõi trạng thái giao dịch: <a :href="urlStaticPaymentView" target="_blank" rel="nofollow">{{ urlStaticPaymentView }}</a></li>-->
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </a-tab-pane>
<!--            <a-tab-pane key="company_manual_transfer">-->
<!--              <span slot="tab">-->
<!--                <a-icon type="dollar"/>-->
<!--                Thanh toán có lấy VAT-->
<!--              </span>-->
<!--              <div class="bank-section">-->
<!--                <template v-for="bankAccount in LIST_BANK_ACCOUNT">-->
<!--                  <div v-if="bankAccount.type === 'company'" :key="bankAccount.id" class="bank-account-container">-->
<!--                    <div class="status-box">-->
<!--                      <strong style="text-transform: uppercase; margin-right: 8px">Thông tin thanh toán</strong>-->
<!--                    </div>-->
<!--                    <div class="bank-info">-->
<!--                      <strong>{{ bankAccount.bankName }}</strong>-->
<!--                      <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="20px" width=""-->
<!--                           :alt="`${bankAccount.bankName}_logo`">-->
<!--                    </div>-->
<!--                    <div v-if="bankAccount.accountAddress">{{ bankAccount.accountAddress }}</div>-->
<!--                    <div class="bank-info">-->
<!--                      <span>Số tài khoản: </span>-->
<!--                      <div>-->
<!--                        <strong>{{ bankAccount.accountNumber }}</strong>-->
<!--                        <div class="tooltip">-->
<!--                          <a-button type="default" size="small"-->
<!--                                    @click="onClickCopy(bankAccount.accountNumber, 'company_account_number_tooltip')">-->
<!--                            <span class="tooltiptext" id="company_account_number_tooltip">Đã copy</span>-->
<!--                            <span>copy</span>-->
<!--                          </a-button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="bank-info">-->
<!--                      <span>Chủ tài khoản: </span>-->
<!--                      <div>-->
<!--                        <strong style="text-transform: uppercase">{{ bankAccount.accountName }}</strong>-->
<!--                        <div class="tooltip">-->
<!--                          <a-button type="default" size="small"-->
<!--                                    @click="onClickCopy(bankAccount.accountName, 'company_account_owner_tooltip')">-->
<!--                            <span class="tooltiptext" id="company_account_owner_tooltip">Đã copy</span>-->
<!--                            <span>copy</span>-->
<!--                          </a-button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="bank-info">-->
<!--                      <span>Số tiền: </span>-->
<!--                      <div v-if="voucherItem.discountPrice">-->
<!--                        <strong>{{ voucherItem.discountPrice | formatCurrency }}</strong>-->
<!--                        <div class="tooltip">-->
<!--                          <a-button type="default" size="small"-->
<!--                                    @click="onClickCopy(voucherItem.discountPrice, 'company_discount_price_tooltip')">-->
<!--                            <span class="tooltiptext" id="company_discount_price_tooltip">Đã copy</span>-->
<!--                            <span>copy</span>-->
<!--                          </a-button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div v-else>-->
<!--                        <strong>{{ voucherItem.price | formatCurrency }}</strong>-->
<!--                        <div class="tooltip">-->
<!--                          <a-button type="default" size="small" @click="onClickCopy(voucherItem.price, 'company_price_tooltip')">-->
<!--                            <span class="tooltiptext" id="company_price_tooltip">Đã copy</span>-->
<!--                            <span>copy</span>-->
<!--                          </a-button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="bank-info">-->
<!--                      <span>Nội dung chuyển khoản <strong class="text-required">(bắt buộc)</strong>: </span>-->
<!--                      <div>-->
<!--                        <span class="payment-status status-process">{{ voucherCode }}</span>-->
<!--                        <div class="tooltip">-->
<!--                          <a-button type="default" size="small" @click="onClickCopy(voucherCode, 'company_transfer_content_tooltip')">-->
<!--                            <span class="tooltiptext" id="company_transfer_content_tooltip">Đã copy</span>-->
<!--                            <span>copy</span>-->
<!--                          </a-button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="cta-section flex-row justify-space-between">-->
<!--                      <div>-->
<!--                        <p class="status-box" v-if="percentPaymentProgress >= 0">-->
<!--                          <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="payment-status">Chờ thanh toán</span>-->
<!--                          <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100"-->
<!--                                class="payment-status status-process">Đang xác minh thanh toán <span-->
<!--                            v-if="timeCountDown"> - <strong>{{ timeCountDown | formatTime }}</strong></span></span>-->
<!--                          <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon-->
<!--                            type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>-->
<!--                          <span v-else-if="isSuccessfulPayment === false">-->
<!--                          <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span><span-->
<!--                            class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span-->
<!--                            class="text-action" @click="onClickSupport">hỗ trợ</span></span>-->
<!--                        </span>-->
<!--                        </p>-->
<!--                        &lt;!&ndash;                  <p v-if="$route.name === 'payment'">Thời gian <span v-if="timeCountDown > 0">còn lại:</span><span v-else>đã </span> <strong>{{timeCountDown | formatTime}}</strong></p>&ndash;&gt;-->
<!--                      </div>-->
<!--                      <template v-if="timeCountDown > 0 && !isSuccessfulPayment && percentPaymentProgress === 0">-->
<!--                        <a-button type="primary" size="large" @click="onClickVerify()">-->
<!--                          <a-icon type="check-circle"/>-->
<!--                          Xác nhận đã chuyển khoản-->
<!--                          &lt;!&ndash;                  <a-spin style="color: white; margin-left: 4px" v-if="percentPaymentProgress > 0"></a-spin>&ndash;&gt;-->
<!--                        </a-button>-->
<!--                      </template>-->
<!--                      <template v-else-if="isSuccessfulPayment">-->
<!--                        <a-button type="primary" size="large" @click="onClickSearch">-->
<!--                          Bắt đầu sử dụng-->
<!--                        </a-button>-->
<!--                      </template>-->
<!--                      <template v-else-if="isSuccessfulPayment === false">-->
<!--                        <a-button type="primary" size="large" @click="onClickReBuy">-->
<!--                          Làm lại-->
<!--                        </a-button>-->
<!--                      </template>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;              <a-progress v-if="percentPaymentProgress > 0" :percent="percentPaymentProgress" :status="progressStatus" :show-info="progressInfo"/>&ndash;&gt;-->
<!--                    <div class="note-section">-->
<!--                      <strong class="text-required">Quan trọng:</strong>-->
<!--                      <ul class="section-content">-->
<!--                        <li>Nội dung chuyển khoản bắt buộc là <span-->
<!--                          class="payment-status status-process">{{ voucherCode }}</span> để việc thanh toán được xác minh tự-->
<!--                          động (2-5 phút)-->
<!--                        </li>-->
<!--                        &lt;!&ndash;                  <li v-if="urlStaticPaymentView">Theo dõi trạng thái giao dịch: <a :href="urlStaticPaymentView" target="_blank" rel="nofollow">{{ urlStaticPaymentView }}</a></li>&ndash;&gt;-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </div>-->
<!--            </a-tab-pane>-->
            <a-tab-pane key="qrcode_transfer">
              <span slot="tab">
                <a-icon type="scan"/>
                VNPayQR Code
              </span>
              <div class="bank-section flex flex-col items-center justify-center">
                <p class="text-3xl font-bold text-center text-gray-700">Quét mã VNPayQR với tính năng chuyển khoản</p>
                <div class="relative mt-4" style="width: min-content; --border-width: 40px; overflow: hidden">
                  <img class="" :src="urlQRCode" alt="qrcode" height="250px" width="250px" style="transform: scale(1.05)">
                  <div class="absolute top-0 left-0 border-t-3 border-l-3 border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
                  <div class="absolute top-0 right-0 border-t-3 border-r-3 border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
                  <div class="absolute bottom-0 left-0 border-b-3 border-l-3 border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
                  <div class="absolute bottom-0 right-0 border-b-3 border-r-3 border-primary-500" style="width: var(--border-width); height: var(--border-width); z-index: 2"></div>
                </div>
                <p><a-icon type="scan" style="transform: scale(1.5)" class="mr-2"/> <span> Sử dụng app ngân hàng của bạn để chuyển khoản qua quét mã VNPayQR</span></p>
                <div class="cta-section flex-col items-center">
                  <div class="">
                    <strong class="text-required">Quan trọng:</strong>
                    <span> Bấm "Xác nhận đã chuyển khoản" sau khi chuyển tiền thành công</span>
                  </div>
                  <div class="mb-5">
                    <p class="status-box text-center" v-if="percentPaymentProgress >= 0">
                      <span v-if="percentPaymentProgress === 0 && $route.name === 'payment'" class="payment-status">Chờ thanh toán</span>
                      <span v-if="$route.name !== 'payment' || percentPaymentProgress > 0 && percentPaymentProgress < 100"
                            class="payment-status status-process">Đang xác minh thanh toán <span
                        v-if="timeCountDown"> - <strong>{{ timeCountDown | formatTime }}</strong></span></span>
                      <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon
                        type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>
                      <span v-else-if="isSuccessfulPayment === false">
                    <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span>
                    <br>
                    <span class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span
                      class="text-action" @click="onClickSupport">hỗ trợ</span></span>
                    </span>
                    </p>
                  </div>
                  <template v-if="timeCountDown > 0 && !isSuccessfulPayment && percentPaymentProgress === 0">
                    <a-button type="primary" size="large" @click="onClickVerify(1/2)">
                      <a-icon type="check-circle"/>
                      Xác nhận đã chuyển khoản
                    </a-button>
                  </template>
                  <template v-else-if="isSuccessfulPayment">
                    <a-button type="primary" size="large" @click="onClickSearch">
                      Bắt đầu sử dụng
                    </a-button>
                  </template>
                  <template v-else-if="isSuccessfulPayment === false">
                    <a-button type="primary" size="large" @click="onClickReBuy">
                      Làm lại
                    </a-button>
                  </template>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import {
  ALL_PRICING_PLAN_V2,
  LIST_BANK_ACCOUNT,
  TRANSACTION_STATE
} from "@/constant/pricing/PricingPlansConstant";
import {formatCurrency} from "@/helper/FormatHelper";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {
  applyDiscountVoucher,
  createVoucher, findPlanInfo, getPricingPlanDuration, getTransactionData,
  setPaidTransaction, updatePricingPlans,
  verifyPaymentInterval
} from "@/service/payment/PaymentService";
import {
  addVoucherWaiting,
  removeVoucherWaiting,
  resetVoucherInfo,
  setVoucherInfo, updateProfileUserCurrent
} from "@/service/user/UserProfileService";
import {getPaidUserInformation, getUserPermissionFromRoles} from "@/service/user/UserPermissionService";
import {copyToClipboard} from "@/helper/StringHelper";
import {URL_WEB} from "@/config/ConfigEndpoint";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import Default from "@/layout/Default";
import {sleep} from "@/helper/TimeHelper";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import MobileDefault from "@/layout/MobileDefault";

const SECONDS_PER_VERIFY_REQUEST = 2
const SECONDS_TIMEOUT_REQUEST = 60 * 10
const SECONDS_AUTO_INCREASE_PERCENT = 0.1

export default {
  name: "PaymentView",
  components: {
    Default,
    MobileDefault
  },
  data() {
    let timeCountDown = SECONDS_TIMEOUT_REQUEST
    if (this.$route.name !== 'payment') {
      timeCountDown = 0
    }
    this.selectedVoucherId = this.$route.query['voucherId'] || this.selectedVoucherId
    this.voucherCode = this.$route.query['voucherCode'] || this.voucherCode
    let discountVoucherCode = this.$route.query['discountVoucherCode']
    let discountVoucherName = this.$route.query['discountVoucherName']
    return {
      currentStep: 0,
      processStep: 0,
      timeCountDown: timeCountDown,
      voucherItem: null,
      LIST_BANK_ACCOUNT: LIST_BANK_ACCOUNT,
      progressStatus: 'active',
      progressInfo: false,
      MESSENGER_ID,
      urlStaticPaymentView: null,
      timeOutPayment: SECONDS_TIMEOUT_REQUEST,
      isDisplayDiscountVoucherInput: false,
      isInvalidDiscountVoucher: false,
      isAppliedDiscountVoucher: false,
      isPlanCreating: false,
      discountVoucherCode: discountVoucherCode,
      discountVoucherName: discountVoucherName,
      localPricingPlanDuration: null
    }
  },
  async mounted() {
    if (!this.selectedVoucherId) {
      await this.$router.push({name: 'pricing'})
    }
    window.scroll(0, 0)
    await sleep(200)
    await updatePricingPlans()
    this.voucherItem = findPlanInfo(this.selectedVoucherId)
    this.localPricingPlanDuration = getPricingPlanDuration(this.voucherItem.id)
    let voucherInfo = null
    if (this.discountVoucherCode) {
      this.voucherCode = this.discountVoucherCode
      this.discountVoucherCode = this.discountVoucherName
      voucherInfo = await getTransactionData(this.voucherCode)
      this.isAppliedDiscountVoucher = true
      this.paymentQRCode = voucherInfo.payment_qrcode
    } else if ( !this.voucherCode) {
      const voucher = await createVoucher(this.voucherItem.type)
      if (!voucher) {
        await this.$router.push({name: 'pricing'})
      }
      this.voucherCode = voucher.voucher_code
      this.paymentQRCode = voucher.payment_qrcode
    }
    if (!this.paymentQRCode) {
      voucherInfo = await getTransactionData(this.voucherCode)
      this.paymentQRCode = voucherInfo.payment_qrcode
    }
    this.voucherItem.discountPrice = voucherInfo?.voucher_price
    this.voucherItem.discountDirectValue = voucherInfo?.discount_direct_value
    this.voucherItem.discountPercent = voucherInfo?.discount_percent
    verifyPaymentInterval(
      this.voucherCode,
      async (transactionState) => {
        await this.finishVerifyPayment(transactionState)
      },
      async (transactionState) => {
        await this.finishVerifyPayment(transactionState)
      },
      SECONDS_PER_VERIFY_REQUEST * 2,
      this.timeOutPayment
    )
  },
  beforeDestroy() {
    this.percentPaymentProgress = 0
    this.isSuccessfulPayment = null
  },
  methods: {
    async onClickVerify(timeInterval = SECONDS_PER_VERIFY_REQUEST) {
      timeInterval = typeof timeInterval === "number" ? timeInterval :  SECONDS_PER_VERIFY_REQUEST
      if (this.percentPaymentProgress > 0 || this.timeCountDown === 0) {
        return
      }
      let transactionData = await setPaidTransaction(this.voucherCode)
      if (!transactionData.expired_time) {
        this.finishVerifyPayment(TRANSACTION_STATE.ERROR)
        return
      }
      let expired_time = new Date(transactionData.expired_time) - new Date()
      this.timeOutPayment = Math.floor(expired_time / 1000)
      if (this.timeOutPayment <= 0) {
        this.finishVerifyPayment(TRANSACTION_STATE.ERROR)
        return
      }
      this.timeCountDown = this.timeOutPayment
      this.percentPaymentProgress = 0
      this.isSuccessfulPayment = null
      this.progressStatus = 'active'
      this.progressInfo = false
      this.urlStaticPaymentView = `${URL_WEB}${this.$route.fullPath}/${this.selectedVoucherId}/${this.voucherCode}`
      setVoucherInfo(this.selectedVoucherId, this.voucherCode)
      addVoucherWaiting([this.voucherCode])
      // const product = generateContentTrackingFb(this.selectedVoucherId, 1, this.voucherItem.price)
      // fbTrackingInitiateCheckout([product])
      const progressLoop = setInterval(async () => {
        let percentPaymentProgress = this.$store.state.UserModule.percentPaymentProgress + SECONDS_AUTO_INCREASE_PERCENT / this.timeOutPayment * 100
        await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, percentPaymentProgress)
      }, 1000 * SECONDS_AUTO_INCREASE_PERCENT)
      let timeLoop = setInterval(() => {
        this.timeCountDown = this.timeCountDown !== 0 ? this.timeCountDown - 1 : 0
      }, 1000)
      setTimeout(() => {
        clearInterval(timeLoop)
        this.finishVerifyPayment(false)
      }, 1000 * this.timeOutPayment)
      verifyPaymentInterval(
        this.voucherCode,
        async (transactionState) => {
          await this.finishVerifyPayment(transactionState)
          clearInterval(progressLoop)
        },
        async (transactionState) => {
          await this.finishVerifyPayment(transactionState)
          clearInterval(progressLoop)
        },
        timeInterval,
        this.timeOutPayment
      )
    },
    async onRemoveDiscountVoucher() {
      await this.$router.push({query: {'discountVoucherCode': undefined, 'voucherId': this.selectedVoucherId}})
      window.location.reload()
    },
    async onApplyDiscountVoucher() {
      this.isInvalidDiscountVoucher = false
      let voucher = await applyDiscountVoucher(this.discountVoucherCode, this.voucherItem.type)
      if (!voucher) {
        this.isInvalidDiscountVoucher = true
        return
      }
      await this.$router.push({
        query: {
          'discountVoucherName': this.discountVoucherCode,
          'discountVoucherCode': voucher.voucher_code,
          'voucherId': this.selectedVoucherId
        }
      })
      window.location.reload()
    },
    onClickShowDiscountVoucher() {
      this.isDisplayDiscountVoucherInput = true
    },
    onClickCancelVoucher() {
      if (this.isAppliedDiscountVoucher) {
        this.onRemoveDiscountVoucher()
        return
      }
      this.onCloseVoucherPopup()
    },
    onCloseVoucherPopup() {
      this.isDisplayDiscountVoucherInput = false
      this.isInvalidDiscountVoucher = false
      this.isPlanCreating = false
      this.discountVoucherCode = null
      this.discountVoucherName = null
    },
    onClickCopy(text, tooltipId) {
      let result = copyToClipboard(text)
      if (result) {
        document.getElementById(tooltipId).classList.add('show')
        setTimeout(() => {
          document.getElementById(tooltipId).classList.remove('show')
        }, 700)
      }
    },
    onClickSearch() {
      this.$router.push({name: 'Home'})
    },
    onClickReBuy() {
      // if (window) {
      //   window.location.reload()
      // } else {
      this.onChangeVoucher()
      // }
    },
    onClickSupport() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSupport}`, true)
    },
    onChangeVoucher() {
      this.$router.push({name: 'pricing'})
    },
    async onChangePricingPlanDuration(pricingPlanDurationId) {
      this.isPlanCreating = true
      this.selectedVoucherId = ALL_PRICING_PLAN_V2[this.voucherItem.id].types[pricingPlanDurationId]
      const voucher = await createVoucher(this.selectedVoucherId)
      if (!voucher?.voucher_code) {
        this.$notification['error']({
          message: 'Tạo gói cước không thành công',
          description: 'Đã có lỗi xảy ra, vui lòng thử lại trong giây lát',
        })
        this.isPlanCreating = false
        return
      }
      await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, 0)
      await this.$store.dispatch(`UserModule/${MutationUser.setIsSuccessfulPayment}`, null)
      await this.$store.dispatch(`UserModule/${MutationUser.setVoucherCode}`, voucher.voucher_code)
      await this.$store.dispatch(`UserModule/${MutationUser.paymentQRCode}`, voucher.payment_qrcode)
      await this.$router.push({query: {'voucherCode': this.voucherCode, 'voucherId': this.selectedVoucherId}})
      this.voucherItem = findPlanInfo(this.selectedVoucherId)
      if (this.discountVoucherCode) {
        await this.onApplyDiscountVoucher()
      } else {
        this.isPlanCreating = false
      }
      verifyPaymentInterval(
        this.voucherCode,
        async (transactionState) => {
          await this.finishVerifyPayment(transactionState)
        },
        async (transactionState) => {
          await this.finishVerifyPayment(transactionState)
        },
        SECONDS_PER_VERIFY_REQUEST * 2,
        this.timeOutPayment
      )
    },
    async updatePaidUserInfo() {
      let voucherInformation = await getPaidUserInformation()
      if (!voucherInformation) {
        return
      }
      const expirationDate = new Date(voucherInformation.end_query_time)
      const isExpired = new Date() - expirationDate > 0
      let freeSearchCount = voucherInformation.remain_free_search_count
      freeSearchCount = freeSearchCount && typeof freeSearchCount === 'number'
        ? freeSearchCount
        : 0
      this.$store.dispatch(`UserModule/${MutationUser.setIsExpired}`, isExpired)
      this.$store.dispatch(`UserModule/${MutationUser.setExpirationDate}`, expirationDate)
      this.$store.dispatch(`UserModule/${MutationUser.freeSearchCount}`, freeSearchCount)
      const response = await updateProfileUserCurrent()
      let permissionName = getUserPermissionFromRoles(response?.roles)?.name
      this.$store.commit(`UserModule/${MutationUser.permissionAvailableName}`, permissionName)
      this.$store.commit(`UserModule/${MutationUser.permissionPlanName}`, permissionName)
    },
    async finishVerifyPayment(transactionState) {
      let percent = this.$store.state.UserModule.percentPaymentProgress
      let paymentStatus = this.$store.state.UserModule.isSuccessfulPayment
      if (percent === 100 && typeof paymentStatus === "boolean") {
        return
      }
      await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, 100)
      await this.$store.dispatch(`UserModule/${MutationUser.setIsSuccessfulPayment}`, transactionState === TRANSACTION_STATE.COMPLETE)
      switch (transactionState) {
        case TRANSACTION_STATE.COMPLETE: {
          resetVoucherInfo()
          removeVoucherWaiting([this.voucherCode])
          await this.updatePaidUserInfo()
          // const product = generateContentTrackingFb(this.selectedVoucherId, 1, this.voucherItem.price)
          // fbTrackingPurchase([product])
          break
        }
        case TRANSACTION_STATE.ERROR: {
          removeVoucherWaiting([this.voucherCode])
          break
        }
        case TRANSACTION_STATE.WAITING: {
          break
        }
      }
    }
  },
  computed: {
    urlQRCode: {
      get() {
        return this.paymentQRCode ? `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=${this.paymentQRCode}` : null
      },
    },
    voucherCode: {
      get() {
        return this.$store.state.UserModule.voucherCode
      },
      async set(value) {
        await this.$store.dispatch(`UserModule/${MutationUser.setVoucherCode}`, value)
      }
    },
    paymentQRCode: {
      get() {
        return this.$store.state.UserModule.paymentQRCode
      },
      async set(value) {
        await this.$store.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.paymentQRCode}`, value)
      }
    },
    selectedVoucherId: {
      get() {
        return this.$store.state.UserModule.selectedVoucherId
      },
      async set(value) {
        await this.$store.dispatch(`UserModule/${MutationUser.setSelectedVoucherId}`, value)
      }
    },
    percentPaymentProgress: {
      get() {
        return this.$store.state.UserModule.percentPaymentProgress
      },
      async set(value) {
        await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, value)
      }
    },
    isSuccessfulPayment: {
      get() {
        return this.$store.state.UserModule.isSuccessfulPayment
      },
      async set(value) {
        await this.$store.dispatch(`UserModule/${MutationUser.setIsSuccessfulPayment}`, value)
      }
    },
  },
  watch: {
    isSuccessfulPayment(newtValue) {
      if (typeof newtValue === "boolean") {
        this.progressStatus = newtValue ? 'success' : 'exception'
        this.progressInfo = true
      }
    }
  },
  mixins: [
    mixinStoreGetterGlobal,
  ],
  filters: {
    formatCurrency,
    formatTime(value) {
      if (typeof value !== "number") {
        return 'hết hạn'
      }
      let sec = value % 60
      let min = (value - sec) / 60
      if (min === 0 && sec === 0) {
        return 'hết hạn'
      } else {
        return `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-select-selection-selected-value {
  width: 100%;
}

::v-deep .ant-tabs-nav-scroll {
  text-align: center;
}

.payment-page {
  width: 100%;
  background-color: white;
  position: relative;
  font-size: 16px;
  margin: 16px;

  .page-content {
    width: 90%;
    min-width: 500px;
    max-width: 1000px;
    margin: auto;
    padding: 16px;

    .header-serction {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .title {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }
    }

    .payment-section {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: flex-start;
      margin: 16px 0;

      .voucher-info-section {
        margin-right: 60px;
        text-align: center;

        .plan {
          height: 270px;
          width: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid $--color-primary;
          border-radius: 8px;
          padding: 12px;
          margin: 12px 0 0 0;

          .plan-name {
            font-size: 18px;
            font-weight: $--font-weight-primary;
          }

          .plan-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-grow: 1;
          }

          .plan-content {
            font-size: 34px;
            line-height: 38px;
            padding-bottom: 4px;
            color: $--color-primary;

          }

          .plan-sub-content {
            font-size: 18px;
            line-height: 20px;
          }

          .plan-pricing {
            font-weight: $--font-weight-primary;
            font-size: 22px;
            margin: 4px;
          }

          .old-price {
            font-size: 16px;
            font-weight: normal;
            text-decoration: line-through;
            color: gray;
          }
        }

        .action-container {
          display: flex;
          justify-content: space-between;
        }

        .discount-voucher-container {
          margin: 8px 0;
          font-size: 10px;
          width: 240px;
        }
      }

      .bank-section {
        flex-grow: 1;
        max-width: 620px;
        line-height: 2.2rem;

        .bank-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }

    .cta-section {
      display: flex;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .note-section {
      text-align: left;
      margin-top: 40px;
    }
  }
}

.btn-disable {
  background-color: #B2B2B2;
}

.time-countdown {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba($--color-primary, 0.2);
  color: $--color-primary
}

.payment-status {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba(gray, 0.2);
  color: gray;

  &.status-process {
    background-color: rgba($--color-primary, 0.2);
    color: $--color-primary;
  }

  &.status-success {
    padding: 8px;
    background-color: rgba(#127C49, 0.2);
    color: #127C49;
  }

  &.status-failure {
    margin-right: 4px;
    background-color: rgba(#ee0033, 0.2);
    color: #ee0033;
  }
}

.mobile-view {

  .payment-status {
    background-color: transparent;

    &.status-process {
      padding: 0;
      background-color: transparent;
      color: $--color-primary
    }

    &.status-success {
      background-color: transparent;
      color: #127C49;
    }

    &.status-failure {
      margin-right: 0;
      background-color: transparent;
      color: #ee0033;
    }
  }
}

.bank-section {

  .bank-info-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    border-bottom: 1px solid #E3E3E3;
  }

  .cta-mobile-section {
    font-size: 12px;
    display: flex;
    justify-content: center;
    text-align: center;

    .cta-btn {
      padding: 8px;
      color: white;
      border-radius: 0.25rem;
      cursor: pointer;
      background-color: #ff7227;
      margin-top: 16px;
      margin-bottom: 16px;
      min-width: 180px;
    }
  }
}

.border-left-line {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 1);
}

.border-left-line-mobile {
  border-left: 1px solid #ff7227;
  padding-right: 4px;
  font-size: 0.875rem;
}

.dash-border-bottom {
  width: 100%;
  border-bottom: 1px gray dashed;
  margin: 8px 0;
}

.voucher-container {

  .voucher-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 32px;
    padding: 4px 11px;

    .remove-action {
      font-size: 16px;
      margin-left: 12px;
      color: gray;
      cursor: pointer;

      &:hover {
        color: $--color-primary;
      }
    }
  }
}

.text-note {
  margin-top: 4px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
}

.text-action {
  text-decoration: underline;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: $--color-primary;
  }
}

.text-highlight {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba($--color-primary, 0.2);
  color: $--color-primary;
}

.text-required {
  color: #ee0033;
  text-transform: uppercase;
  font-size: 18px;
}

.text-error {
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
  color: #ee0033;
}

.text-success {
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
  color: #127C49;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 8px;

  button {
    border: none;
    background: rgba(180, 185, 230, 0.27);
    color: #2180F0;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: #2180F0;
  position: absolute;
  z-index: 1;
  text-align: left;
  opacity: 0;
  transition: opacity 0.5s;
  left: 110%;
}

.bank-info-mobile {
  .tooltip .tooltiptext {
    left: 0;
    bottom: 100%;
  }
}

.tooltip .tooltiptext#popup_upgrade_tooltip {
  left: 100%;
  bottom: 0;
  padding-left: 10px;
}

.tooltip .tooltiptext.show {
  visibility: visible;
  opacity: 1;
}
</style>
